
.card {
    border-radius: 6px;
    box-shadow: none;
    margin-bottom: 24px;
    .card-header {
        border-bottom: 1px solid;
    }
}

.card-body {
    padding: 20px;
}

.panel-title,
.panel-header {
    margin-top: 0 !important;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #ffffff;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}

.panel-default {
    .panel-heading {
        color: #333;
        background-color: #f5f5f5;
        border-color: #ddd;
    }
}

.panel {
    .panel-color {
        .panel-heading {
            padding: 10px 15px;
        }
    }
}

.panel-primary > .panel-heading {
    background-color: $primary;
}

.panel-success > .panel-heading {
    background-color: #0acf97;
}

.panel-info > .panel-heading {
    background-color: #40a4f1;
}

.panel-warning > .panel-heading {
    background-color: #f5b225;
}

.panel-danger > .panel-heading {
    background-color: #ec536c;
}
