
.mce-menu-item-normal.mce-active {
    background-color: $primary !important;
}

.mce-menu-item:hover,
.mce-menu-item.mce-selected,
.mce-menu-item:focus {
    background-color: $primary !important;
}

.form-group {
    margin-bottom: 15px;
    label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: 700;
    }
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-horizontal {
    .form-group {
        .control-label {
            padding-top: 7px;
            margin-bottom: 0;
            text-align: right;
        }
    }
}

.form-horizontal {
    .form-group {
        .form-control {
            line-height: 1.42857143;
            background-image: none;
            border-radius: 4px;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        }
    }
}

.input-group-btn {
    background-color: var(--#{$variable-prefix}header-bg);
    margin: 0 !important;
    display: flex;
    align-items: center;
    border-radius: 6px;
}

.input-group-text {
    border: 1px solid var(--#{$variable-prefix}border-color);
}

.form-check {
    padding-left: 0;
    margin-bottom: 0;
}

.checker {
    span {
        // background: none !important;
        border-radius: 6px;
        input {
            border: 1px solid var(--#{$variable-prefix}border-color);
            background-color: var(--bs-input-bg);
        }
    }
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    &::after {
        content: "";
        display: inline-block;
        font-size: 14px;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

.dropdown-toggle-split {
    &::after {
        margin-left: 0 !important;
    }
}

.input-group {
    .dropdown-menu {
        border: 1px solid var(--#{$variable-prefix}border-color);
    }
}