// // button scss
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        color: $white;
        background-color: $value;
        border-color: $value;
        &:hover,
        &:focus,
        &:active {
            color: $white;
            border-color: rgba($value, 0.5);
            background-color: rgba($value, 0.9);
        }
    }
}

.btn-light {
    color: $dark;
    &:hover {
        color: $dark;
    }
}

.button, .btn {
    line-height: 1;
}

// btn-link
.btn-link {
    text-decoration: none !important;
    &:hover {
        color: $primary;
    }
}

// button icon

.btn-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    width: calc(#{$btn-padding-y * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    padding: 0;
    i,
    svg,
    img {
        vertical-align: middle;
    }

    &.btn-sm {
        height: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
        width: calc(#{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    }

    &.btn-lg {
        height: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
        width: calc(#{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$btn-border-width * 2});
    }
}

/* close Buttons */
.btn-close {
    background: none;
    &:focus {
        box-shadow: none;
    }
}