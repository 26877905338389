.nav {
    display: flex;
    &::before {
        content: '';
        display: flex;
    }
    &:after {
        content: '';
        clear: both;
    }
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    border-color: #E5E9EC;
    border-bottom-color: transparent;
}

.nav-tabs {
    border-color: #E5E9EC;
}

.nav-tabs > li > a {
    color: #2b2b2b;
}

/* .nav > li > a:focus,
.nav > li > a:hover {
    background-color: #fff;
} */

.nav-tabs > li > a:hover {
    border-color: #edf1f4;
    border-bottom: transparent;
}

.nav-justified.nav-tabs > li > a:hover {
    border-color: #ddd;
    border-bottom: transparent;
}

.nav-tabs > li > a {
    border-radius: 0!important;
    border-top-left-radius: 3px!important;
    border-top-right-radius: 3px!important;
    color: $muted;
    border-bottom: 1px solid #E2E2E2;
}

.nav-tabs {
    border-bottom: 1px solid #E2E2E2!important;
}

.tab-default {
    .nav-tabs {
        .nav-item {
            .nav-link {
                border: none;
                position: relative;
                border-bottom: 1px transparent;
                &.active {
                    color: $primary;
                    background-color: transparent;
                }
                &::after {
                    content: '';
                    border-bottom: 1px solid $primary;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transform: scale(0);
                    transition: all 250ms ease 0s;
                }
            }
        }
    }
}

.tabs-left {
    .nav-tabs {
        .nav-item {
            .nav-link {
                border: none;
                position: relative;
                border-right: 1px transparent;
                &.active {
                    color: $primary;
                    &::after {
                        content: '';
                        border-right: 1px solid $primary;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}

.tabs-right {
    .nav-tabs {
        .nav-item {
            .nav-link {
                border: none;
                position: relative;
                border-left: 1px transparent;
                &.active {
                    color: $primary;
                    &::after {
                        content: '';
                        border-left: 1px solid $primary;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                    }
                }
            }
        }
    }
}

.tab-default {
    .nav-item {
        .nav-link.active{
            &::after {
                content: '';
                transform: scale(1);
            }
        }
    }
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: 1px solid transparent;
    color: #1B2733;
}

/* div:not(.tabs-right):not(.tabs-left) > .nav-tabs > li:not(.active) > a:hover {
    background: transparent;
    border-color: transparent;
    border-bottom: 1px solid #B6BABD;
    color: #3D464D;
} */

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: $primary;
    /* cursor: default; */
    background-color: #fff;
    border-bottom-color: $primary;
}

.nav-pills .nav-link {
    color: #777;
}

/* .nav-pills .nav-link.active {
    color: $primary;
    background-color: transparent;
} */

.nav-pills > li > a {
    color: #777;
    border-radius: 0px!important;
}

.nav-pills > li > a {
    border-radius: 4px!important;
    padding: 10px 15px!important;
    margin-right: 5px!important;
}

/* .nav-pills > li > a:hover {
    background-color: #E2E2E2;
} */

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #fff;
    background-color: $primary;
}

.tabs-left .nav > li:first-child > a {
    margin-left: 15px!important;
}

.tabs-right .nav > li > a {
    text-align: right;
    margin-left: -1px!important;
    margin-right: 0px;
    padding-right: 15px;
}

.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    display: flex;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-bottom: 0;
    border-bottom: 0;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #E2E2E2;
    border-bottom: 0!important;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
    border-color: transparent;
    border-right-color: $primary!important;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px!important;
    border-bottom-left-radius: 3px!important;
    border-top-right-radius: 0!important;
    color: $primary;
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #E2E2E2;
    border-bottom: 0!important;
}

.tabs-right > .nav-tabs > li > a {
    padding-left: 15px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
    background: transparent;
    border-color: transparent;
    border-left: 1px solid #B6BABD!important;
    border-bottom: none;
    color: #3D464D;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
    border-color: transparent;
    border-left-color: $primary!important;
    border-bottom: none;
    border-top-right-radius: 3px!important;
    border-bottom-right-radius: 3px!important;
    border-top-left-radius: 0!important;
    color: $primary;
}

.nav-tabs.nav-justified > li > a {
    margin-bottom: -1px;
    margin-left: 0px;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover {
    border-color: transparent;
    border-bottom: 1px solid $primary;
    color: $primary;
}

.nav-tabs.nav-justified {
    border-bottom: 1px solid #E2E2E2;
}

.accordion-group {
    .accordion {
        border-radius: 3px;
        box-shadow: none;
        margin-bottom: 20px;
        .accordion-header {
            border-bottom: 1px solid transparent;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            .accordion-button {
                margin-bottom: 0;
                font-size: 16px;
                background-color: var(--#{$variable-prefix}body-bg);
                border-color: #ddd;
                padding: 4px 15px;
                &:not(.collapsed) {
                    color: $primary;
                }
                &:focus {
                    box-shadow: none;
                }
                &::after {
                    background-image: none;
                }
            }
        }
        .accordion-collapse {
            .accordion-body {
                background-color: var(--#{$variable-prefix}header-bg);   
            }
        }
    }
}

.nav-justified {
    .nav-item {
        .nav-link {
            width: auto;
        }
    }
}

.nav-pills {
	>li {
		>a.active {
			background-color: $primary !important;
			color: #fff;
		}
		>a {
			&:hover {
				background-color: $primary;
				color: #fff;
			}
		}
	}
}