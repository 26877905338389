
body {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    font-size: 15px;
}

html {
    overflow-x: hidden;
    position: relative;
    min-height: 100%;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* margin: 10px 0; */
}

h1 {
    line-height: 43px;
}

h2 {
    line-height: 35px;
}

h3 {
    line-height: 30px;
}

h3 small {
    color: #444444;
}

h4 {
    line-height: 22px;
}

h4 small {
    color: #444444;
}

h5 small {
    color: #444444;
}

b {
    font-weight: 700;
}

* {
    outline: none !important;
}

a,
a:hover,
a:active,
a:focus {
    outline: none!important;
    text-decoration: none!important;
}
