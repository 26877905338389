@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700|Quattrocento+Sans:400,700');

.mb-24 {
    margin-bottom: 24px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-15 {
    margin-bottom: 15px;
}

.w-xs {
    min-width: 80px;
}

.w-sm {
    min-width: 95px;
}

.w-md {
    min-width: 110px;
}

.w-lg {
    min-width: 140px;
}

$font-size-mixing: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 22, 20, 32, 40;

@each $font-size-mixing in $font-size-mixing {
  .fs-#{$font-size-mixing} {
    font-size: #{$font-size-mixing}px !important;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(243, 246, 249);
}

.header-title {
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    font-weight: 700;
    text-transform: capitalize;
}

.header-title::after {
    content: "";
    background-color: $primary;
    width: 38px;
    height: 3px;
    border-radius: 5px;
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0px;
}

.modal-example {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}

.simplebar-scrollbar {
    &::before {
        background-color: $gray-400;
    }
}

.breadcrumb-item + .breadcrumb-item {
    &::before {
        content: "\f105";
        font-family: "FontAwesome";
    }
}