// navbar scss
/* Page Header */
.page-header {
    margin: 0px;
    border-bottom: none;
    min-height: 77px;
    padding: 10px 20px 10px 20px;
    background-color: var(--#{$variable-prefix}body-bg);
    position: fixed;
    top: 0;
    left: $vertical-menu-width;
    right: 0;
    z-index: 1002;
    .search-form {
        left: 0;
        right: 0;
        position: absolute;
        background-color: var(--#{$variable-prefix}header-bg);
        border-radius: 6px;
        height: 56px;
        margin-left: 20px;
        margin-right: 20px;
        z-index: 41;
        margin-top: -81px;
        transition: all .2s;
        font-weight: 400;
        opacity: 0;
        form {
            background-color: var(--#{$variable-prefix}header-bg);
            border-radius: 6px;
            .input-group {
                background-color: var(--#{$variable-prefix}header-bg);
                border-radius: 6px;
                .form-control {
                    color: var(--black) !important;
                    background-color: var(--#{$variable-prefix}header-bg);
                    border-radius: 6px;
                    border: none;
                    height: 56px;
                    box-shadow: none;
                    padding-left: 25px;
                    padding-bottom: 7px;
                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                }
                #close-search {
                    background-color: $primary;
                    color: #ffffff;
                    border: none;
                    height: 44px;
                    border-radius: 50%;
                    box-shadow: none;
                    margin: 0 16px 0px 0;
                    padding: 0 12px;
                    &:hover,
                    &:focus {
                        background-color: $primary;
                        opacity: 1;
                        border: none;
                        outline: 0;
                        box-shadow: none;
                    }
                    i {
                        font-size: 20px;
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
    .navbar {
        .navbar-collapse {
            .navbar-nav {
                .dropdown {
                    display: flex;
                    .dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            &.collapse:not(.show) {
                display: flex !important;
                justify-content: space-between;
            }
            .navbar-nav {
                >li {
                    >.dropdown-menu {
                        margin-top: 8px !important;
                    }
                }
            }
        }
    }
}

.header-item {
    height: 56px;
    display: flex;
    align-items: center;
    .btn-icon {
        &::after {
            display: none;
        }
    }
    .btn-topbar {
        height: 42px;
        width: 42px;
        color: var(--black);
    }
    .dropdown-menu {
        box-shadow: 0 5px 10px rgba($black,.12);
        border: 1px solid var(--#{$variable-prefix}border-color);
        position: absolute;
        z-index: 1000;
        &.show {
            top: 56px !important;
        }
        li {
            font-size: 14px;
            font-weight: 400;
            a {
                padding: 8px 15px;
                font-size: 14px;
                font-weight: 400;
                display: block;
                color: var(--#{$variable-prefix}black);
                &:hover {
                    background: var(--#{$variable-prefix}header-bg);
                }
                i {
                    margin-right: 10px;
                }
            }
        }
        .drop-title {
            display: block;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background: var(--#{$variable-prefix}header-bg);
            padding: 12px;
            color: var(--black);
            margin: 0px;
            .drop-title-link {
                float: right;
                display: inline!important;
                padding: 0px!important;
                height: 18px;
                width: 18px;
                overflow: hidden;
                vertical-align: text-top;
                line-height: 18px!important;
                text-align: center;
                border-radius: 100%;
                i {
                    margin-right: 0!important;
                }
            }
        }
        
        .dropdown-notifications {
            height: auto!important;
            max-height: 250px;
            .dropdown-oc {
                li {
                    a {
                        padding-right: 0;
                        span.notification-badge {
                            width: 40px;
                            height: 40px;
                            display: block;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 100%;
                            display: inline-block;
                            vertical-align: top;
                            margin-top: 6px;
                            color: #fff;
                            i {
                                margin-right: 0px;
                            }
                        }
                        span.notification-info {
                            width: 204px;
                            display: block;
                            padding: 5px 15px;
                            display: inline-block;
                            vertical-align: middle;
                            font-weight: normal;
                            small {
                                color: #aaa;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-lg {
        width: 280px;
    }
}

.dropdown.dropup > .dropdown-menu:after,
.dropdown.dropup > .dropdown-menu:before,
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before {
    content: '';
    display: none !important;
}

.navbar-left .dropdown-menu:before,
.dropdown-menu.dropdown-menu-left:before {
    content: '';
    left: 16px;
    right: auto;
}

.navbar-left .dropdown-menu:after,
.dropdown-menu.dropdown-menu-left:after {
    content: '';
    left: 17px;
    right: auto;
}

.navbar-right .dropdown-menu:before,
.dropdown-menu.dropdown-menu-right:before {
    content: '';
    right: 16px;
    left: auto;
}

.navbar-right .dropdown-menu:after,
.dropdown-menu.dropdown-menu-right:after {
    content: '';
    right: 17px;
    left: auto;
}

.dropdown-menu.title-caret:after {
    content: '';
    border-bottom: 7px solid #fff;
}

.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus,
.dropdown-menu > li.active > a:active,
.dropdown-menu > li.active > a,
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus {
    background: #f7f7f7;
    color: #2b2b2b;
}

.dropdown-content {
    padding-top: 0;
    padding-bottom: 0;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #eee;
    border-color: transparent;
}

// chat box right sidebar
.page-right-sidebar {
    width: 231px;
    background-color: var(--#{$variable-prefix}header-bg);
    height: 100%;
    min-height: 100%;
    right: -231px;
    top: 0;
    z-index: 9999;
    position: fixed;
    transition: all .1s;
    &.visible {
        right: 0;
    }
    &#chat-right-sidebar {
        transition: all .1s;
        opacity: 0;
        &.visible {
            opacity: 1;
        }
    }
    .page-right-sidebar-inner {
        .right-sidebar-top {
            background: var(--#{$variable-prefix}header-bg);
            display: flex;
            justify-content: space-between;
            height: 56px;
            border-bottom: 1px solid var(--#{$variable-prefix}border-color);
            .right-sidebar-close {
                float: right;
                color: var(--black);
                opacity: 0.6;
                font-size: 16px;
                padding: 10px 20px;
                height: 55px;
                line-height: 34px;
                transition: all .2s;
                &:hover {
                    background: transparent;
                    opacity: 1;
                }
            }
            .nav-tabs {
                .nav-item {
                    .nav-link {
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: bold;
                    }
                }
            }
            .right-sidebar-tabs {  
                .nav-tabs {
                    border-bottom: 0!important;
                    .nav-link {
                        display: flex;
                        align-items: center;
                        height: 55px;
                        font-size: 12px;
                        font-weight: 700;
                        text-align: center;
                        padding: 0 12px ;
                        border: 0;
                        margin-right: 0;
                        border-radius: 0;
                        transition: all .2s;
                        margin-left: 0;
                        color: var(--muted);
                        &.active {
                            background-color: var(--#{$variable-prefix}body-bg);
                            color: var(--black);
                        }
                        &:hover {
                            color: var(--black);
                        }
                    }
                }
                .nav {
                    >li {
                        >a {
                            padding: 11px 15px;
                        }
                    }
                }
            }
        }
        .right-sidebar-content {
            .tab-content {
                margin-top: 0;
            }
        }
    }
}

.nav {
	>li {
		>a {
			font-weight: 400;
			margin: 0 15px;
			padding: 10px 0;
		}
	}
}

.nav-pills {
	>li {
		>a.active,
        &:hover {
			background-color: $primary !important;
			color: #fff;
		}
	}
}

.nav-tabs.nav, .nav-pills.nav {
	>li {
		&:first-child {
			>a {
				margin-left: 0px;
			}
		}
	}
}

.navbar {
    .dropdown {
        .dropdown-menu.dropdown-list {
            li:first-child {
                margin-top: 5px;
            }
            li:last-child {
                margin-bottom: 5px;
            }
        }
        .dropdown-menu.dropdown-list.theme-settings {
            li:first-child {
                margin-top: 0;
            }
        }
    }
}

.popover {
    border: 1px solid #e5e9ec;
    border-radius: 4px;
    box-shadow: none;
}

.popover.top > .arrow {
    border-top-color: #e5e9ec;
}

.popover.right > .arrow {
    border-right-color: #e5e9ec;
}

.popover-title {
    padding: 15px 15px 10px 15px;
    color: #637282;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    background-color: transparent;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.popover-content {
    padding: 10px 15px 15px 15px;
}


// fixe header scss
.page-header-fixed {
    .page-header {
        position: fixed;
        z-index: 99;
        width: calc(100% - 240px);
        right: 0;
        top: 0;
    }
    .page-container {
        .page-header {
            padding-right: 15px;
            right: 0;
            margin: auto;
        }
    }
}

.page-header-fixed.page-sidebar-collapsed {
    .page-container.container {
        .page-header {
            padding-right: 15px;
            padding-left: 75px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

@media (min-width: 768px) {
    .page-header-fixed {
        .page-container.container {
            .page-header {
                width: 750px;
            }
        }
    }
}

@media (min-width: 992px) {
    .page-header-fixed {
        .page-container.container {
            .page-header {
                width: 970px;
            }
        }
    }
}

@media (min-width: 1200px) {
    .page-header-fixed {
        .page-container.container {
            .page-header {
                width: 1170px;
            }
        }
    }
}


// page-sidebar-collapsed scss
.page-sidebar-collapsed.page-header-fixed {
    .page-header {
        left: 60px;
    }
}

::placeholder {
    color: var(--#{$variable-prefix}black) !important;
}

.page-sidebar-fixed .page-header {
    z-index: 41;
}

.page-header {
    .navbar-default {
        background-color: var(--#{$variable-prefix}header-bg) !important;
        border: 0;
        margin-bottom: 0;
        height: 56px;
        border-radius: 6px;
    }
}

.page-header .navbar-default .container-fluid,
.page-header .navbar-default .navbar-collapse {
    height: 56px!important;
}

.page-header .navbar-default .navbar-brand i.fixed-sidebar-button-active {
    background: #fff;
}

.page-header .navbar-nav > li.v-divider {
    display: block;
    width: 1px;
    height: 26px;
    background: #F3F3F3;
    margin: 21px 15px;
}

.page-header {
    .navbar-nav {
        >li {
            &.user-dropdown {
                >a {
                    margin-right: 0px;
                    padding-top: 20px;
                }
            }
            >a {
                padding-top: 18px;
                padding-bottom: 18px;
                height: 56px;
                color: #4b4b4b !important;
                text-transform: uppercase;
                >img {
                    width: 36px;
                    margin-top: -10px;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }
}

.page-header .navbar-nav > li > a:hover,
.page-header .navbar-nav > li > a:link,
.page-header .navbar-nav > li > a:focus {
    color: var(--black) !important;
}

.open:not(.active-page) > a {
    color: $primary;
}

.active-page.open > a {
    font-weight: 500;
}

.active-page.open {
    >a {
        i {
            color: #2b2b2b;
        }
    }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    color: #1f2229;
    background-color: transparent;
}

.page-header {
    .navbar-collapse {
        padding-right: 0px;
        padding-left: 0px;
        overflow-x: visible;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
    .page-header {
        .navbar-nav {
            >li {
                >a {
                    >i {
                        font-size: 20px;
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
    .page-header {
        .navbar-nav {
            >li {
                >a {
                    >i[class^="icon-"] {
                        font-size: 19px;
                        vertical-align: top;
                    }
                }
            }
        }
    }
}


/* Page Right Sidebar */
.right-sidebar-tabs .nav-tabs > li.active > a,
.right-sidebar-tabs .nav-tabs > li.active > a:focus,
.right-sidebar-tabs .nav-tabs > li.active > a:hover {
    background-color: #fff;
    border: none;
    color: #4F5862!important;
    opacity: 1!important;
    text-transform: capitalize;
}

.right-sidebar-tabs > .nav-tabs > li > a:focus,
.right-sidebar-tabs > .nav-tabs > li > a:hover {
    text-decoration: none;
    background-color: transparent;
    opacity: 1;
    border-bottom-color: transparent!important;
}

.navbar-collapse {
	.form-switch {
		.form-check-input {
            background-image: none;
            background: transparent;
            border: none;
            cursor: pointer;
            &:focus {
                background: none;
                box-shadow: none;
            }
            &::before {
                content: "\F594";
                font: normal normal normal 24px/1 "Material Design Icons";
                font-size: 20px;
            }
			&:checked {
				background-image: none;
			}
		}
	}
}

// light dark mode icon
[data-bs-theme=dark] {
    .mdi-weather-night {
        &::before {
            content: "\f5a8" !important;
        }
    }
}

.chat-list {
    .chat-item {
        overflow: hidden;
        position: relative;
        display: block;
        padding: 5px 0;
        &:last-child {
            border-bottom: 1px solid transparent;
        }
        &:hover {
            background: var(--#{$variable-prefix}body-bg);
        }
        img {
            width: 40px;
            border-radius: 100%;
            margin: 10px;
            margin-left: 20px;
        }
        .user-avatar {
            position: relative;
            display: inline-block;
            overflow: hidden;
            float: left;
        }
    }
}

.chat-list {
    .chat-item {
        &.active-user {
            .user-avatar {
                &:after {
                    content: ' ';
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: $success;
                    position: absolute;
                    bottom: 9px;
                    right: 9px;
                    border: 2px solid var(--#{$variable-prefix}border-color);
                }
            }
        }
    }
}

.chat-list {
    .chat-item {
        .chat-info {
            float: left;
            overflow: hidden;
            padding: 13px 0;
            width: 100px;
            span.chat-author {
                display: block;
                color: var(--black);
                opacity: 0.6;
                font-size: 13px;
                line-height: 15px;
                padding: 0;
                margin-bottom: 4px;
            }
            span.chat-text {
                display: block;
                color: var(--muted);
                opacity: 0.6;
                font-size: 13px;
                line-height: 15px;
                padding: 0;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            span.chat-time {
                display: block;
                color: var(--muted);
                opacity: 0.6;
                font-size: 12px;
                line-height: 15px;
                padding: 0;
                margin: 0;
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: -7.5px;
            }
        }
        &.unread .chat-info span {
            font-weight: 600;
            opacity: 1;
        }
    }
}

.load-more-messages {
    display: block;
    text-align: center;
    width: 100%;
    margin: 15px auto;
    font-size: 14px;
    color: var(--black);
    opacity: 0.6;
}

.load-more-messages {
    &:hover {
        opacity: 1;
    }
}


.page-right-sidebar-inner {
    height: 100%;
}

.right-sidebar-content {
    height: calc(100% - 56px);   
}

.right-sidebar-settings {
    .settings-title {
        padding: 10px 25px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        display: block;
        margin: 5px 0 0 0;
        background: transparent;
        color: var(--muted);
        opacity: 0.6;
    }
}

span.chat-title {
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
    color: var(--black);
    background-color: var(--#{$variable-prefix}body-bg);
}

.sidebar-setting-list {
    li {
        padding: 12px 25px;
        font-size: 13px;
        color: var(--black);
        &:last-child {
            border-color: transparent;
        }
        .switchery {
            float: right;
            border-color: var(--#{$variable-prefix}border-color) !important;
        }
    }
}


// common
.nav-link {
    &:hover {
        color: $dark;
    }
}

.nav-tabs {
    .nav-link {
        &.active {
            background: transparent;
        }
    }
}

[data-bs-theme=dark]:root {
    .nav-link:hover {
        color: $white;
    }
}

.page-sidebar-collapsed {
    .page-header {
        left: $vertical-menu-width-sm;
    }
}

@media (max-width: 991px) {
    .page-header {
        left: 0;
    }
    .page-sidebar-collapsed.page-sidebar-visible {
        .page-header {
            left: $vertical-menu-width-sm;
        }
    }  
}

@media (max-width: 778px) {
    .page-header {
        left: 0;
    }
}


// .chat-top-info {
//     float: left;
//     padding: 10px 0px 10px 20px;
// }

// .chat-top-info span {
//     display: block;
// }

// .chat-top-info span.chat-name {
//     color: #555;
//     font-size: 12px;
//     text-transform: uppercase;
//     font-weight: bold;
// }

// .chat-top-info span.chat-state {
//     color: #4F5862;
//     font-size: 12px;
//     opacity: 0.6;
// }

// .chat-more-nav {
//     float: right;
//     display: block;
//     position: relative;
//     width: 15px;
//     height: 15px;
//     margin: 13px 5px;
//     text-align: center;
// }

// .chat-more-nav > a {
//     display: block;
//     position: relative;
//     font-size: 18px;
//     width: 100%;
//     height: 100%;
// }

// .chat-start-date {
//     width: 100%;
//     color: #767676;
//     font-size: 12px;
//     text-align: center;
//     padding: 10px 0;
// }

// .right-sidebar-chat {
//     height: 100%!important;
//     padding-bottom: 20px;
//     display: block;
// }

// .chat-bubble.them .chat-bubble-img-container {
//     float: left;
// }

// .chat-bubble .chat-bubble-text-container {
//     max-width: 60%;
//     display: block;
// }

// .chat-bubble.them .chat-bubble-text-container {
//     float: left;
//     clear: right;
// }

// .chat-bubble.me .chat-bubble-text-container {
//     float: right;
//     clear: right;
// }

// .chat-bubble {
//     position: relative;
//     width: 100%;
//     display: block;
//     overflow: hidden;
// }

// .chat-bubble img {
//     width: 38px;
//     height: 38px;
//     border-radius: 100%;
// }

// .chat-bubble.them img {
//     margin-left: 20px;
// }

// .chat-bubble span.chat-bubble-text {
//     padding: 5px 10px;
//     min-height: 28px;
//     margin: 0 10px;
//     position: relative;
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 1.8;
//     float: left;
//     clear: both;
//     word-wrap: break-word;
//     max-width: 100%;
// }

// .chat-bubble.them span.chat-bubble-text {
//     margin-left: 10px;
//     background: #787e86;
//     color: #fff;
//     border-radius: 4px;
//     border-top-left-radius: 0px;
// }

// .chat-bubble.me span.chat-bubble-text {
//     margin-left: 10px;
//     margin-right: 30px;
//     background: #f1f1f1;
//     float: right;
//     border-radius: 4px;
//     border-top-right-radius: 0px;
// }

// .chat-bubble span.chat-bubble-text {
//     margin-top: 4px;
// }

// .chat-bubble span.chat-bubble-text:first-child {
//     margin-top: 0;
// }

// .chat-bubble span.chat-bubble-text:last-child {
//     margin-bottom: 5px;
// }

// .chat-bubble span.chat-bubble-text:first-child:before {
//     content: '';
//     width: 0;
//     height: 0;
//     border-style: solid;
// }

// .chat-bubble.them span.chat-bubble-text:first-child:before {
//     content: '';
//     border-width: 0 10px 10px 0;
//     border-color: transparent #787e86 transparent transparent;
//     position: absolute;
//     top: 0;
//     left: -9px;
// }

// .chat-bubble.me span.chat-bubble-text:first-child:before {
//     content: '';
//     border-width: 10px 10px 0 0;
//     border-color: #f1f1f1 transparent transparent;
//     position: absolute;
//     top: 0;
//     right: -9px;
// }

// .chat-write input {
//     background: transparent;
//     border: 0;
//     box-shadow: none;
//     border-top: 1px solid #f1f1f1;
//     height: 56px;
//     border-radius: 0;
//     padding: 0 20px;
// }

// .chat-write input:focus {
//     box-shadow: none;
//     border-color: #f1f1f1;
// }

.search-open {
    .search-form {
        margin-top: 0;
        opacity: 1;
    }
}

